import React, { useState } from 'react';
import { useTranslation, Trans, useEnvironment, useBi } from '@wix/yoshi-flow-editor';
import { Dialog, Button, TextField, Text } from 'wix-ui-tpa';
import { Confirm as ConfirmIcon } from '@wix/wix-ui-icons-common';
import { loyaltyUouClick } from '@wix/bi-logger-loyalty-uou/v2';

import { ModalType } from '../../hocs/modal-context';
import { style, classes } from './coupon-code-modal.st.css';
import { DataHook } from '../../../../../types/data-hook';

export interface CouponCodeModalType {
  type: ModalType.COUPON_CODE;
  props: {
    couponCode: string;
    couponName: string;
  };
}

interface CouponCodeModalProps {
  onClose: () => void;
  couponCode: string;
  couponName: string;
}

export const CouponCodeModal: React.FC<CouponCodeModalProps> = ({ onClose, couponCode, couponName }) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();
  const bi = useBi();
  const [copied, setCopied] = useState(false);

  const onClick = () => {
    navigator.clipboard.writeText(couponCode);
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
    bi.report(
      loyaltyUouClick({
        value: 'copy_coupon_code',
        origin: 'popup',
      }),
    );
  };

  return (
    <Dialog isOpen={true} onClose={onClose} notFullscreenOnMobile={isMobile} data-hook={DataHook.CouponCodeModal}>
      <div className={style(classes.modal, { isMobile })}>
        <div className={classes.textsContainer}>
          <Text tagName="h2" className={classes.header}>
            <Trans
              i18nKey="app.coupon-code-modal.title"
              defaults="You got a coupon<br/>{couponName}"
              values={{ couponName }}
              components={{ break: <br /> }}
            />
          </Text>
          <Text tagName="p" className={classes.description}>
            {t('app.coupon-code-modal.description')}
          </Text>
        </div>
        <div className={classes.actionPoints}>
          <TextField className={classes.couponCode} value={couponCode} readOnly />
          <Button upgrade className={classes.copyButton} onClick={onClick}>
            {copied ? (
              <ConfirmIcon size="24px" className={classes.confirmIcon} />
            ) : (
              t('app.coupon-code-modal.copy-button')
            )}
          </Button>
        </div>
      </div>
    </Dialog>
  );
};
